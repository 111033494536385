<template>
  <div class="screen-container ">
    <div class="screen-container-header flex-center">
      <div class="header-center text-center flex-center font-white font-28 font-weight-700">电动自行车充电站 AI智慧安全监管平台
      </div>
    </div>
    <div class="content flex-vertical-center">
      <vue-particles
          class="login-background"
          color="#97D0F2"
          :particleOpacity="0.7"
          :particlesNumber="50"
          shapeType="circle"
          :particleSize="4"
          linesColor="#97D0F2"
          :linesWidth="1"
          :lineLinked="true"
          :lineOpacity="0.4"
          :linesDistance="150"
          :moveSpeed="3"
          :hoverEffect="true"
          hoverMode="grab"
          :clickEffect="true"
          clickMode="push">
      </vue-particles>
      <div class="form">
        <img src="../assets/images/logo-1.png" class="mt40 margin-center" alt=""
             style="width: 155px;height: 40px;display: block">
        <h3 style="text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.20);"
            class="font-24 font-weight-700 mt20 font-white text-center">电动自行车·AI安全充电智慧监管平台</h3>

        <div class="mt45">
          <div class="form-item flex-between containers60 margin-center">
            <div class="form-label font-20" style="color: #76CEFF;">账号</div>
            <input type="text" v-model="form_data.account" class="font-input"/>
          </div>
          <div class="form-item flex-between containers60 margin-center mt24">
            <div class="form-label font-20" style="color: #76CEFF;">密码</div>
            <input type="password" v-model="form_data.password" class="font-input"/>
          </div>
        </div>
        <div class="flex-center mt24">
          <div class="log-btn cursor-pointer" @click="login">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            登 录</div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
let _this;
import md5 from "js-md5";
export default {
  name: "Login",
  data() {
    return {
      form_data: {
        account: '',
        password: '',
      },
    }
  },
  created() {
    _this = this;
  },
  mounted() {

  },
  methods: {
    login() {
      // console.log(Qs.stringify(this.form));return;
      let param = _this.form_data;
      param.password = md5(param.password);
      _this.$post('/admin/login', param, {headers: {content_type: "multipart/form-data"}})
          .then(request => {
            if (request.code == 0) {
              // sessionStorage.setItem('token',request.data.token);
              _this.$cache.set('token', request.data.token);
              _this.$cache.set('user_info', request.data);
              // _this.$message({
              //   type: 'success',
              //   message: '登录成功',
              //   onClose: () => {
              //     _this.$router.push('/street');
              //   }
              // });

              _this.$jayMessage({
                content: "登陆成功",
                type: "success",
                onClose:function () {
                  _this.$router.push('/street');
                }
              }).show();

            } else {
              // this.$message({
              //   type: 'error',
              //   message: request.msg
              // });

              _this.$jayMessage({
                content: request.msg,
                type: "err",

              }).show();
            }
            // console.log(request)
          }).catch(error => {
        this.$message({
          type: 'info',
          message: error.message
        });
      });
      // console.log(Qs.stringify(this.form));
      // this.$router.push('/');
    },
    get_info() {

    },
  },

}
</script>
<style src="../assets/css/Base.css"></style>
<style scoped>
.screen-container .content {
  position: relative;
  width: 100%;
  height: calc(100vh - 64px);
  /*background-image: url("../assets/images/bg-3.png");*/
  /*background-repeat: no-repeat;*/
  /*background-position: center;*/
  /*background-size: cover;*/
}
.screen-container .content .login-background{
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.screen-container .content .form {
  width: 870px;
  height: 495px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #3EB9FF;
  background: rgba(10, 23, 56, 0.80);
  box-shadow: 0px 0px 24px 0px #5987FC inset;
  z-index: 2;
}

.screen-container .content .form .form-item .font-input {
  border-radius: 8px;
  background: rgba(10, 23, 56, 0.80);
  width: 90%;
  height: 48px;
  flex-shrink: 0;
  text-indent: 20px;
  color: #fff;
  box-sizing: border-box;
  border: 0.5px solid #3EB9FF;
}

.screen-container .content .form .form-item .font-input:focus {
  box-shadow: 0px 0px 16px 0px #5987FC inset;
  border: 0.5px solid #3EB9FF;
}

/*.screen-container .content .form .log-btn {*/
/*  position: relative;*/
/*  width: 236px;*/
/*  height: 64px;*/
/*  flex-shrink: 0;*/
/*  color: #FFF;*/
/*  text-shadow: 0px 2px 4px #1460B2;*/
/*  font-size: 24px;*/
/*  font-style: normal;*/
/*  font-weight: 700;*/
/*  line-height: 64px;*/
/*  text-align: center;*/
/*  margin: 64px auto 0;*/
/*  cursor: pointer;*/
/*  padding: 4px; !* 这是为了确保内容不会覆盖边框 *!*/
/*  background: linear-gradient(90deg, #4A95EF 0%, #2EC8ED 54.69%, #56A2F0 100%);*/
/*  !*border:4px solid transparent;*!*/
/*  !*border-image: radial-gradient(ellipse at center, #4A95EF 0%, #2EC8ED 54.69%, #56A2F0 100%) 1;*!*/
/*  border-radius: 32px;*/
/*}*/

/*.screen-container .content .form .log-btn:before{*/
/*  content: "";*/
/*  position: absolute;*/
/*  top: -4px;*/
/*  right: -4px;*/
/*  bottom: -4px;*/
/*  left: -4px;*/
/*  z-index: -1;*/
/*  background-image: linear-gradient(90deg, #B4FDF7 0%, #2EC8ED 54.69%, #DDFFF9 100%);!* 设置渐变色 *!*/
/*  border-radius: 32px; !* 设置圆角 *!*/
/*}*/
/*.screen-container .content .form .log-btn:hover{*/
/*  background: linear-gradient(90deg, #B4D6FF 0%, #ABEFFF 48.96%, #B9DBFF 100%);*/
/*}*/
/*.screen-container .content .form .log-btn:hover:before{*/
/*  background-image: linear-gradient(90deg, #B4FDF7 0%, #2EC8ED 54.69%, #DDFFF9 100%);!* 设置渐变色 *!*/
/*}*/


.screen-container .content .form .log-btn {
  position: relative;
  display: inline-block;
  width: 200px;
  padding: 10px 20px;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 16px;
  letter-spacing: 4px;
  text-align: center;
}

.screen-container .content .form .log-btn:hover {
  background: #03e9f4;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #03e9f4,
  0 0 25px #03e9f4,
  0 0 50px #03e9f4,
  0 0 100px #03e9f4;
}

.screen-container .content .form .log-btn span {
  position: absolute;
  display: block;
}

.screen-container .content .form .log-btn span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,100% {
    left: 100%;
  }
}

.screen-container .content .form .log-btn span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  animation: btn-anim2 1s linear infinite;
  animation-delay: .25s
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,100% {
    top: 100%;
  }
}

.screen-container .content .form .log-btn span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  animation: btn-anim3 1s linear infinite;
  animation-delay: .5s
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,100% {
    right: 100%;
  }
}

.screen-container .content .form .log-btn span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: btn-anim4 1s linear infinite;
  animation-delay: .75s
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,100% {
    bottom: 100%;
  }
}



/*.screen-container .content .form .log-btn:active{*/
/*  color: #42C9F1;*/
/*  text-shadow: 0px 2px 4px #1460B2;*/
/*  font-size: 24px;*/
/*  font-style: normal;*/
/*  font-weight: 700;*/
/*  line-height: normal;*/
/*  letter-spacing: -0.333px;*/
/*  background-image: linear-gradient(90deg, #51A6C0 0%, #2EC8ED 54.69%, #72B7CD 100%);!* 设置渐变色 *!*/
/*  border-radius: 32px; !* 设置圆角 *!*/
/*}*/
/*.screen-container .content .form .log-btn:active:before{*/
/*  background-image: linear-gradient(90deg, #072548 0%, #2EC8ED 54.69%, #042546 100%);!* 设置渐变色 *!*/
/*}*/
</style>